import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/notification.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private service: NotificationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((e: HttpErrorResponse) => {
        let error = e.error;
        if (e.status === 401) {
          this.service.error('Your session has been deleted. Please log-in again.');
        } else if (error && error.errors && Object.keys(error.errors).length !== 0) {
          if (typeof error.errors === 'object') {
            error.errors = Object.values(error.errors);
            error.errors.forEach((err: any, key) => {
              const er = err && Array.isArray(err) ? err[0] : err;
              this.service.error(er);
            });
          } else {
            error.errors.forEach((err: any) => {
              if (typeof err === 'string') {
                this.service.error(err);
              } else {
                this.service.error(err.description);
              }
            });
          }
        } else if (error?.traceId && error?.detail) {
          this.service.error(error?.detail);
        } else {
          this.service.error('An error has occurred, please contact admin for support.');
        }
        return throwError(() => e);
      })
    );
  }
}
